const config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    // Backend config
    s3: {
      REGION: process.env.REACT_APP_REGION,
      BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
      REGION: process.env.REACT_APP_REGION,
      URL: process.env.REACT_APP_API_URL,
    },
    cognito: {
      REGION: process.env.REACT_APP_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    fileTypes: [
      {
        start: 0,
        type: 'products',
        reportId: 'Std683_Sales_SupplierSumm_PartNo'
      },
      {
        start: 1,
        type: 'stock',
        reportId: 'Std871_Stock_6mthSalesHistory'
      }
    ],
    import: {
      products: {
        columns: 43,
        components: [
          {
            attribute: 'supplierId',
            header: 'Supplier Id',
            column: 1,
            valueNot: 'Account No.'
          },
          {
            attribute: 'supplierName',
            header: 'Supplier Name',
            column: 11
          },
          {
            attribute: 'productId',
            header: 'Part Id',
            column: 19
          },
          {
            attribute: 'productName',
            header: 'Part Name',
            column: 23
          }
        ]
      },
      stock: {
        columns: 39,
        components: [
          {
            attribute: 'id',
            header: 'Part Id',
            column: 1
          },
          {
            attribute: 'Month1',
            column: 8
          },
          {
            attribute: 'Month2',
            column: 10
          },
          {
            attribute: 'Month3',
            column: 12
          },
          {
            attribute: 'Month4',
            column: 15
          },
          {
            attribute: 'Month5',
            column: 18
          },
          {
            attribute: 'Month6',
            column: 21
          },
          {
            attribute: 'sales',
            header: 'Sales',
            column: 22
          },
          {
            attribute: 'onHand',
            header: 'On Hand',
            column: 24
          },
          {
            attribute: 'onOrder',
            header: 'On Order',
            column: 26
          },
          {
            attribute: 'salesPrice',
            header: 'Sales Price',
            column: 27
          },
          {
            attribute: 'costPrice',
            header: 'Average Cost Price',
            column: 28
          },
          {
            attribute: 'lastSold',
            header: 'Last Sold',
            column: 31
          },
          {
            attribute: 'lastReceived',
            header: 'Last Received',
            column: 33
          },
          {
            attribute: 'globalOnHand',
            header: 'Global on Hand',
            column: 38
          }
        ]
      },
      suppliers: {
        columns: 43,
        components: [
          {
            attribute: 'supplierId',
            header: 'Supplier Id',
            column: 1,
            valueNot: 'Account No.'
          },
          {
            attribute: 'supplierName',
            header: 'Supplier Name',
            column: 12
          }
        ]
      }
    }
  };
  
  export default config;
import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Containers/Home";
import Login from "./Containers/Login";
import NewNote from "./Containers/NewNote";
import Notes from "./Containers/Notes";
import NotFound from "./Containers/NotFound"
import SelectStore from "./Containers/SelectStore"
import NewProduct from "./Containers/Products/New"
import ImportProducts from "./Containers/Products/Import"
import AllProducts from "./Containers/Products/All"
import AllProjects from "./Containers/Projects/All"
import Product from "./Containers/Products/Product"
import Recommendations from "./Containers/Transactions/Recommendations"
import { useAppContext } from "./lib/contextLib";

export default function Routes() {
  const { isAuthenticated, selectedStore } = useAppContext();
  return (
    <Switch>
      {!isAuthenticated && <Route>
            <Login />
        </Route>
      }
      {!selectedStore && <Route>
           <SelectStore /> 
        </Route>
      }
      <Route exact path="/">
        <AllProducts />
      </Route>
      <Route exact path="/notes/new">
        <NewNote />
      </Route>
      <Route exact path="/notes/:id">
        <Notes />
      </Route>
      <Route exact path="/products/new">
        <NewProduct />
      </Route>
      <Route exact path="/products/import">
        <ImportProducts />
      </Route>
      <Route exact path="/products">
        <AllProducts />
      </Route>
      <Route exact path="/projects">
        <AllProjects />
      </Route>
      <Route exact path="/products/:id">
        <Product />
      </Route>
      <Route exact path="/recommendations">
        <Recommendations />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
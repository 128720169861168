import React, { useState, useEffect } from "react";
import { Grid, Container } from '@mui/material';

export default function Notes() {

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Container>
            <h1>Projects</h1>
            <h3>Coming Soon</h3>          
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}
import React, { useState } from "react";
import { Box, TextField, Link, Alert, Typography, Popover } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";

export default function Login() {
  const { userHasAuthenticated, setAuthedUser } = useAppContext();
  const { notifications, setNotifications } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const [haveCode, setHaveCode] = useState(false);
  const [passwordChange, setpasswordChange] = useState(false);
  const [passwordForgot, setpasswordForgot] = useState(false);
  const [passwordChangeError, setpasswordChangeError] = useState('');
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    password1: "",
    password2: "",
    code: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateForgotForm() {
    return fields.email.length > 0;
  }

  function validateNewPassword() {
    if (haveCode) {
      return fields.password1.length > 0 && fields.password1 === fields.password2 && fields.code.length > 0;
    }
    return fields.password1.length > 0 && fields.password1 === fields.password2;
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  async function login() {
    let user = await Auth.currentSession();
    setAuthedUser(user)
    userHasAuthenticated(true);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    setNotifications([])
    setIsLoading(true);
    try {
        let result = await Auth.signIn(fields.email, fields.password);
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUser(result);
          setpasswordChange(true)
          setIsLoading(false)
        } else {
          login()
        }
      } catch (error) {
        let message = error.toString();
  
        // Auth errors
        if (!(error instanceof Error) && error.message) {
          message = error.message;
        }
        setNotifications([{severity: 'error', content: message}])
        setIsLoading(false);
    }
  }

  async function handleForgotSubmit(event) {
    event.preventDefault();
    setNotifications([])
    try {
        let result = await Auth.forgotPassword(fields.email);
        setHaveCode(true);
      } catch (error) {
        let message = error.toString();
  
        // Auth errors
        if (!(error instanceof Error) && error.message) {
          message = error.message;
        }
        setNotifications([{severity: 'error', content: message}])
        setIsLoading(false);
    }
  }

  async function handlePasswordSubmit(event) {
    event.preventDefault();
    setNotifications([])
    setIsLoading(true);
    if (haveCode) {
      Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password1
      ).then(user => {
        setIsLoading(false);
        setpasswordForgot(false)
        setHaveCode(false)
        setNotifications([{severity: 'success', content: "Password updated. Please login"}])
      }).catch(e => {
        setIsLoading(false);
        console.error(e);
        if (e.message && e.message.includes('Password does not conform to policy: ')) {
          setpasswordChangeError(e.message.split('policy: ')[1])
        } else {
          setNotifications([{severity: 'error', content: e.message}])
          setpasswordChangeError("Error processing new password")
        }
      })
    } else {
      Auth.completeNewPassword(
        user,
        fields.password1
      ).then(user => {
        login()
      }).catch(e => {
        setIsLoading(false);
        console.error(e);
        if (e.message && e.message.includes('Password does not conform to policy: ')) {
          setpasswordChangeError(e.message.split('policy: ')[1])
        } else {
          setpasswordChangeError("Error processing new password")
        }
      })
    }
  }

  function loginForm () {
    return <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={handleFieldChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={handleFieldChange}
      />
      <LoadingButton
        type="submit"
        fullWidth
        loading={isLoading}
        disabled={!validateForm()}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </LoadingButton>
      <Link href="#" onClick={() => setpasswordForgot(true)}>Forgot Password</Link>
    </Box>
  }

  function passwordForgotForm () {
    return <Box component="form" onSubmit={handleForgotSubmit} noValidate sx={{ mt: 1 }}>
      <Alert severity="info"><Typography>
        Input your email address to receive a reset code.
      </Typography></Alert>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={fields.email}
        onChange={handleFieldChange}
      />
      <LoadingButton
        type="submit"
        fullWidth
        loading={isLoading}
        disabled={!validateForgotForm()}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Send Code
      </LoadingButton>
      <Link href="#" onClick={() => setHaveCode(true)}>I already have a code</Link>
    </Box>
  }

  function passwordChangeForm () {
    return <Box component="form" onSubmit={handlePasswordSubmit} noValidate sx={{ mt: 1 }}>
      <Alert severity="info"><Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        { haveCode ? "" : "A password change is required."} Please create a new <b>strong</b> password.
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>At least 8 characters long including: numbers, special character, uppercase and lowercase letters</Typography>
      </Popover></Alert>
      <TextField
        error={passwordChangeError.length > 0}
        margin="normal"
        required
        fullWidth
        id="password1"
        label="New Password"
        type="password"
        value={fields.password1}
        autoFocus
        onChange={handleFieldChange}
        helperText={passwordChangeError.length > 0 ? passwordChangeError : null}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Retype Password"
        type="password"
        value={fields.password2}
        id="password2"
        onChange={handleFieldChange}
      />
      { haveCode && <TextField
        margin="normal"
        required
        fullWidth
        label="Code"
        type="text"
        value={fields.code}
        id="code"
        onChange={handleFieldChange}
      />}
      <LoadingButton
        type="submit"
        fullWidth
        loading={isLoading}
        disabled={!validateNewPassword()}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Update Password
      </LoadingButton>
    </Box>
  }

  return (
    <Box
    sx={{
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {
      passwordForgot ? 
        haveCode ? passwordChangeForm() : passwordForgotForm() :
        passwordChange ? passwordChangeForm() : loginForm()
    }
  </Box>
  );
}
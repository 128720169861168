import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


export default function Recommendations() {
  const file = useRef(null);
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [averages, setAverages] = useState(null);

  
const datacolumns = [
    '2021-07',
    '2021-08',
    '2021-09',
    '2021-10',
    '2021-11',
    '2021-12'
  ]

  useEffect(() => {
    function getSMA(data) {
        let result = {}
        data.forEach((d) => {
            Object.keys(d.locations).forEach((l) => {
                let localData = d.locations[l]
                let ave = (localData[datacolumns[datacolumns.length -1]] + localData[datacolumns[datacolumns.length -2]] + localData[datacolumns[datacolumns.length -3]]) / 3
                if (result[l]) {
                    result[l] = [
                        ...result[l],
                        {
                            ave,
                            onHand: localData.onHand,
                            id: d.id
                        }
                    ]
                } else {
                    result[l] = [
                        {
                            ave,
                            onHand: localData.onHand,
                            id: d.id
                        }
                    ]
                }
            })
        })
        Object.keys(result).forEach((l) => {
            result[l].sort((a, b) => {
                return (a.ave - a.onHand) - (b.ave - b.onHand)
            }).reverse()
        })
        console.error(result)
        return result
    }

    function getAverages() {
      return API.get("tbc", "/items/averages");
    }

    async function loadAverages() {
      try {
        const result = await getAverages();
        setAverages(getSMA(result));
        
      } catch (e) {
        onError(e);
      }
    }

    loadAverages();
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 170 },
    { field: 'ave', headerName: 'Simple Moving Average', width: 200 },
    { field: 'onHand', headerName: 'Stock on Hand', width: 200 }
  ];

  return (
    <Container maxWidth="md">
        {averages && Object.keys(averages).map((l) => {
            return l !== "" && <Paper sx={{ mt: 6 }}><div style={{ height: 400, width: '100%' }}>
                <h2>{l}</h2>
                <DataGrid
                    rows={averages[l].filter((a) => a.ave > a.onHand)}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </div></Paper>
        })}
    </Container>

  );
}
import React, { useRef, useState, useEffect } from "react";
import { LinearProgress, Box, Card, Container, CardContent, TextField, Typography, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import * as XLSX from "xlsx";
import moment from 'moment';
import { API } from "aws-amplify";
import config from "../../config";
import { useAppContext } from "../../lib/contextLib";

export default function NewProduct() {
    const { notifications, setNotifications, selectedStore, setImported, setAllProducts, setAllSuppliers, setAllStock} = useAppContext();
    const file = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [removeExisting, setRemoveExisting] = useState(false);
    const [importLog, setImportLog] = useState('');

    useEffect(() => {
     }, [isLoading]);

    
    function filterRowsByConfig(rows, productConfig) {
        return rows
            .filter((r) => r.length === productConfig.columns)
            .filter((r) => {
                let dataComplete = true
                productConfig.components.forEach((c) => {
                    if (typeof r[c.column] === 'undefined') {
                        dataComplete = false
                    }
                })
                return dataComplete
            })
            .filter((r) => {
                let allowed = true
                productConfig.components.filter((c) => c.valueNot).forEach((c) => {
                    if (r[c.column] === c.valueNot) {
                        allowed = false
                    }
                })
                return allowed
            })
    }

    function handleData(rows) {
        console.error(rows)
        let type = null
        let shift = 0
        let documentDate = null
        console.error(rows)
        if (rows.length > 10 && rows[rows.length - 2]) {
            config.fileTypes.forEach((t) => {
                if (rows[rows.length - 2][t.start] === `[${t.reportId}]`) {
                    setImportLog(`Identified ${t.reportId}`)
                    documentDate = moment(rows[1][36]);
                    type = t.type
                }
                if (rows[rows.length - 2][t.start + 1] === `[${t.reportId}]`) {
                    setImportLog(`Identified ${t.reportId}`)
                    documentDate = moment(rows[1][36]);
                    shift = 1
                    type = t.type
                }
            })            
        }

        if (!type) {
            setNotifications([...notifications, {severity: 'error', content: 'Document not recognized'}])
            setIsLoading(false);
            return 
        }

        let productConfig = config.import[type]
        let shiftedRows = rows.map((r) => r.slice(shift))
        let filteredLengthRows = filterRowsByConfig(shiftedRows, productConfig)
        setImported(true)
        importRowsProduct(filteredLengthRows, type, filterRowsByConfig(shiftedRows, config.import['suppliers']))
        importRowsMonthlyAve(filteredLengthRows, type, documentDate)
    }

    async function getData() {
        setImportLog('Acquiring prerequisite data...')
        let products = await API.get("tbc", `/items/${selectedStore}Products`)
        let suppliers = await API.get("tbc", `/items/${selectedStore}Suppliers`)
        let stock = await API.get("tbc", `/items/${selectedStore}Stock`)
        return {
            allProducts: products.all || [],
            allSuppliers: suppliers.all || [], 
            allStock: stock.all || []
        }
    }

    async function importRowsProduct(tableRows, fileType, supplierRows) {
        if (!fileType || fileType !== 'products') {
            return
        }
        let data = removeExisting ? {} : await getData()
        setImportLog('Importing products...')
        let {allProducts, allSuppliers} = data;
        if (removeExisting) {
            allProducts = []
            allSuppliers = []
        }
        let products = allProducts;
        let suppliers = allSuppliers;
        let components = config.import[fileType].components;
        let supplierComponents = config.import['suppliers'].components;
        if (tableRows.length > 0) {
            let productsToBeAdded = []
            for (const r of tableRows) {
                try {
                    let supplierId = r[components.find((c) => c.attribute === 'supplierId').column]
                    if (!suppliers.find((s) => s.supplierId === supplierId)) {
                        let supplierRow = supplierRows.find((sR) => sR[supplierComponents.find((c) => c.attribute === 'supplierId').column] === supplierId)
                        let supplierName = supplierRow ? supplierRow[supplierComponents.find((c) => c.attribute === 'supplierName').column] : r[components.find((c) => c.attribute === 'supplierName').column]
                        await createItem(`/suppliers`, [{
                            supplierId,
                            supplierName,
                            warehouse: selectedStore,
                            notes: ''
                        }])
                        suppliers = [
                            ...suppliers, 
                            {
                                supplierId,
                                supplierName,
                                warehouse: selectedStore,
                                notes: ''
                            }
                        ]
                    }
                    if (r[components.find((c) => c.attribute === 'productId').column]) {
                        if (!allProducts.find((p) => p.productId === r[components.find((c) => c.attribute === 'productId').column])) {
                            productsToBeAdded = [...productsToBeAdded, { data: {
                                productId: r[components.find((c) => c.attribute === 'productId').column],
                                productName: r[components.find((c) => c.attribute === 'productName').column],
                                supplierId: r[components.find((c) => c.attribute === 'supplierId').column],
                                warehouse: selectedStore,
                                notes: '',
                            }}];
                            products = [
                                ...products,
                                {
                                    productId: r[components.find((c) => c.attribute === 'productId').column],
                                    productName: r[components.find((c) => c.attribute === 'productName').column],
                                    supplierId: r[components.find((c) => c.attribute === 'supplierId').column],
                                    warehouse: selectedStore,
                                    notes: '',
                                }
                            ]
                        } else if (allProducts.find((p) => p.productId === r[components.find((c) => c.attribute === 'productId').column] && p.supplierId !== r[components.find((c) => c.attribute === 'supplierId').column])) {
                            await API.put("tbc", "/products", {
                                body: {
                                    productId: r[components.find((c) => c.attribute === 'productId').column],
                                    warehouse: selectedStore,
                                    UpdateExpression: "SET productName = :productName, supplierId = :supplierId",
                                    ExpressionAttributeValues: {
                                        ":productName": r[components.find((c) => c.attribute === 'productName').column],
                                        ":supplierId": r[components.find((c) => c.attribute === 'supplierId').column],
                                    }
                                }
                            })
                            products = [
                                ...products.filter((p) => p.productId !== r[components.find((c) => c.attribute === 'productId').column]),
                                {
                                    productId: r[components.find((c) => c.attribute === 'productId').column],
                                    productName: r[components.find((c) => c.attribute === 'productName').column],
                                    supplierId: r[components.find((c) => c.attribute === 'supplierId').column],
                                    warehouse: selectedStore,
                                    notes: '',
                                }
                            ]
                            console.error('Double Found: ', r[components.find((c) => c.attribute === 'productId').column])
                        }
                    }
                } catch (e) {
                    setNotifications([...notifications, {severity: 'error', content: e.message}])
                }
            }
            while (productsToBeAdded.length > 0) {
                let upload = productsToBeAdded.splice(0,25)
                await createItem(`/products`, upload.map((u) => u.data))
            }
            await createItem("/items", {
                content: {all: products},
                filename: `${selectedStore}Products`
            })
            await createItem("/items", {
                content: {all: suppliers},
                filename: `${selectedStore}Suppliers`
            })
            setAllProducts([])
            setAllSuppliers([])
            setImportLog('Done.')
            setIsLoading(false)
        } else {
            setNotifications([{severity: 'error', content: 'Could not find items to import'}])
            setIsLoading(false);
        }
    }

    async function importRowsMonthlyAve(tableRows, fileType, documentDate) {
        if (!fileType || fileType !== 'stock') {
            return
        }
        await getData()
        let data = await getData()
        setImportLog('Importing stock movement...')
        let {allProducts} = data;
        let components = config.import[fileType].components;
        if (tableRows.length > 0) {
            let stockToBeAdded = []
            let products = [...allProducts]
            let month = {}
            Array.from(Array(6)).forEach((_, i) => {
                month[i] = documentDate.subtract(1, 'months').format('YYYY-MM')
            })

            for (const r of tableRows) {
                    try {
                        let existing = products.find((p) => p.productId === r[components.find((c) => c.attribute === 'id').column])
                        if (existing) {
                            let data = {
                                internalId: existing.productId,
                                warehouse: selectedStore
                            }
                            components.forEach((c) => data[c.attribute] = r[c.column])
                            
                            Array.from(Array(6)).forEach((_, i) => {
                                data['Month' + (i + 1)] = {
                                    value: data['Month' + (i + 1)],
                                    date: month[i]
                                }
                            })
                            stockToBeAdded = [...stockToBeAdded, data]
                            products = products.filter((p) => p.productId !== r[components.find((c) => c.attribute === 'id').column])
                        }
                    } catch (e) {
                        setNotifications([...notifications, {severity: 'error', content: e.message}])
                    }
            }
            await createItem("/items", {
                content: {all: stockToBeAdded},
                filename: `${selectedStore}Stock`
            })
            while (stockToBeAdded.length > 0) {
                let upload = stockToBeAdded.splice(0,25)
                await createItem(`/stock`, upload)
            }
            setAllStock([])
            setImportLog('Done.')
            setIsLoading(false)
        } else {
            setNotifications([{severity: 'error', content: 'Could not find items to import'}])
            setIsLoading(false);
        }
    }

    function handleFileChange(event) {
        setIsLoading(true);
        file.current = event.target.files[0];
        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
            `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
                1000000} MB.`
            );
        }
        setImportLog('Opening file')
        const reader = new FileReader();
        reader.onload = (e) => {
            const ab = e.target.result
            const wb = XLSX.read(ab, {type:'array', cellDates: true});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {header:1});
            setImportLog('Reading data as xls document')
            handleData(data)
        }
        reader.readAsArrayBuffer(file.current);
    }

    function createItem(path, data) {
        return API.post("tbc", path, {
            body: data
        });
    }

  return (
    <>
    {console.log(importLog)}
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <Box
                noValidate
                sx={{ mt: 1 }}
                component="form"
                autoComplete="off"
            ><Card>
                <CardContent>
                <Typography variant="h5">Import Inventory Report</Typography>
                <FormGroup sx={{ mt: 1 }}>
                    <FormControlLabel control={<Checkbox checked={removeExisting} onChange={() => setRemoveExisting(!removeExisting)} />} label="Remove existing store data before importing" />
                    <TextField
                        required
                        name="file"
                        type="file"
                        id="file"
                        autoComplete="file"
                        disabled = {isLoading}
                        helperText={`Ensure to upload Microsoft Excel (97-2003) versions of reports: Std871_Stock_6mthSalesHistory or Std683_Sales_SupplierSumm_PartNo`}
                        onChange={handleFileChange}
                    />
                </FormGroup>
                </CardContent>
                </Card>
            </Box>
        </Box>
        <Container>
            <Box  sx={{ textAlign: 'center' }}>{isLoading && <LinearProgress />}{importLog}</Box>
        </Container>
    </>
  );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from "react-router-dom";
import { FormControl, Select, InputLabel, MenuItem, Divider } from '@mui/material';
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import tbc from './tbc.png';

const pages = [
  {
    name: 'Products',
    link: '/products'
  },
  {
    name: 'Projects',
    link: '/projects'
  } 
];
const settings = ['Logout'];


const ResponsiveAppBar = () => {
  const { userHasAuthenticated, authedUser, selectedStore, setSelectedStore } = useAppContext();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    return true;
  };

  const handleStoreChange = (event) => {
    localStorage.setItem('selectedStore', event.target.value);
    setSelectedStore(event.target.value)
  }
  const handleCloseUserMenu = (event) => {
    console.error(event)
    if (event.target.textContent === "Logout") {
      Auth.signOut();
      userHasAuthenticated(false);
    }
    setAnchorElUser(null);
  };

  const storeDropDown = () => {
    return <FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant="filled">
      <InputLabel style={{ color: 'white' }} id="store-select-label">Store</InputLabel>
      <Select style={{ color: 'white' }}
        labelId="store-select-label"
        id="store-select"
        value={selectedStore}
        label="Store"
        onChange={handleStoreChange}
      >
        <MenuItem value={'hermanus'}>Hermanus</MenuItem>
        <MenuItem value={'somersetWest'}>Somerset West</MenuItem>
        <MenuItem value={'stellenbosch'}>Stellenbosch</MenuItem>
        <Divider />
        <MenuItem value={'holdings'}>Holdings</MenuItem>
        <MenuItem value={'paardenIsland'}>Paarden Island</MenuItem>
        <MenuItem value={'seaPoint'}>Sea point</MenuItem>
        <MenuItem value={'stillbaai'}>Stillbaai</MenuItem>
      </Select>
    </FormControl>
  }

  const profile = () => {
      return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{authedUser.idToken.payload.email[0].toUpperCase()}</Avatar>
            </IconButton>
            </Tooltip>
            <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            >
            {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
            ))}
            </Menu>
        </Box>
      )
  }

  return (
    <AppBar position="static" enableColorOnDark>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={tbc} alt={"TBC"} />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => handleCloseNavMenu() && history.push(page.link)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
          <img src={tbc} alt={"TBC"} />  TBC
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => history.push(page.link)}
                sx={{ my: 2, color: 'white', display: 'block', pl: 4 }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          {storeDropDown()}
          {authedUser.idToken && profile()}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardHeader, CardContent, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useFormFields, useFormAutocomplete } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import { API } from "aws-amplify";
import { s3Upload } from "../../lib/awsLib";
import config from "../../config";

const filter = createFilterOptions();

export default function NewProduct() {
  const file = useRef(null);
  const history = useHistory();
  const [supplier, setSupplier] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    productCode: "",
    productName: "",
    productNotes: ""
  });

  function validateForm() {
    console.error(fields)
    return fields.productCode.length > 0 && fields.productName.length > 0 && supplier.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
        const attachment = file.current ? await s3Upload(file.current) : null;
        await createProduct({ 
          content: {
            id: fields.productCode,
            name: fields.productName,
            supplier,
            notes: fields.productNote,
          }, 
          attachment,
          type: 'products',
          filename: 'latest'
        });
        history.push("/");
    } catch (e) {
        onError(e);
        setIsLoading(false);
    }
  }

    function createProduct(data) {
        return API.post("tbc", "/items", {
          body: data
        });
    }

  return (
    <Box
        sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        }}
    >
      <Box
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1 }}
        component="form"
        autoComplete="off"
      ><Card raised>
        <CardContent>
        <Typography variant="h5">New Product</Typography>
        <TextField 
          fullWidth 
          id="productCode" 
          label="Code" 
          variant="standard" 
          helperText="Example: 607M-22"
          onChange={handleFieldChange}
          value={fields.productCode}
        />
        <TextField 
          fullWidth 
          id="productName" 
          label="Name" 
          variant="standard" 
          sx={{ mt: 1 }} 
          helperText="Example: ELBOW C-C DELCOP 22MM"
          onChange={handleFieldChange}
          value={fields.productName}
        />
        <Autocomplete
          disablePortal
          id="productSupplier"
          options={[]}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          sx={{ mt: 1 }}
          renderInput={(params) => <TextField {...params} label="Supplier" />}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
  
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }
  
            return filtered;
          }}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setSupplier(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSupplier(newValue.inputValue);
            } else {
              setSupplier(newValue);
            }
          }}
          value={supplier}
        />
        <TextField 
          fullWidth 
          id="productNotes" 
          label="Notes" 
          variant="standard" 
          sx={{ mt: 1 }} 
          multiline
          helperText="Optional Notes"
          onChange={handleFieldChange}
          value={fields.productNotes}
        />
        <LoadingButton
          type="submit"
          fullWidth
          loading={isLoading}
          disabled={!validateForm()}
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
      >
        Create
        </LoadingButton>
        </CardContent>
        </Card>
      </Box>

    </Box>
  );
}
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { Box, TextField, TextareaAutosize } from '@mui/material';
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import { s3Upload } from "../lib/awsLib";
import config from "../config";

export default function NewNote() {
  const file = useRef(null);
  const history = useHistory();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    content: ""
  });

  function validateForm() {
    return fields.content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
        const attachment = file.current ? await s3Upload(file.current) : null;
        await createNote({ content: fields.content, attachment });
        history.push("/");
    } catch (e) {
        onError(e);
        setIsLoading(false);
    }
  }

    function createNote(note) {
        return API.post("tbc", "/notes", {
        body: note
        });
    }

  return (
    <Box
        sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        }}
    >
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextareaAutosize
                margin="normal"
                aria-label="empty textarea"
                placeholder="Empty"
                style={{ width: '99%' }}
                required
                fullWidth
                id="content"
                label="Content"
                name="content"
                autoComplete="content"
                autoFocus
                onChange={handleFieldChange}
                value={fields.content}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="file"
                type="file"
                id="file"
                autoComplete="file"
                onChange={handleFileChange}
            />
            <LoadingButton
                type="submit"
                fullWidth
                loading={isLoading}
                disabled={!validateForm()}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
              Create
            </LoadingButton>
          </Box>
    </Box>
  );
}
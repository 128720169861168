import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import {Container} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useAppContext } from "../../lib/contextLib";
import Typography from '@mui/material/Typography';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
  Legend,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker, ValueScale } from '@devexpress/dx-react-chart';

const columns = [
  'Month6',
  'Month5',
  'Month4',
  'Month3',
  'Month2',
  'Month1'
]

export default function Notes() {
  const {selectedStore } = useAppContext();
  const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [averages, setAverages] = useState(null);

  useEffect(() => {
    function loadProduct() {
      return API.get("tbc", `/products/${selectedStore}/` + id);
    }

    function getAverages(internalId) {
      return API.get("tbc", `/stock/${selectedStore}/` + id);
      //return API.get("tbc", "/items/averages/" + id);
    }

    async function onLoad() {
      try {
        const result = await loadProduct();
        //const { content, attachment } = note;

        /* if (attachment) {
          note.attachmentURL = await Storage.vault.get(attachment);
        } */
        setProduct(result);
        loadAverages(result.internalId)

      } catch (e) {
        onError(e);
      }
    }

    async function loadAverages(internalId) {
      try {
        const result = await getAverages(internalId);
        setAverages(result);
        
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);
  
  function stockToOrder() {
    let temp = Math.ceil((averages['Month1'].value + averages['Month2'].value + averages['Month3'].value) / 3) - averages.onHand || 0
    if (temp > 0) {
      return temp
    }
    return 0
  }

  return (
    <Container>
      <Box
          sx={{
          display: 'flex',
          marginTop: '10px',
          alignItems: 'center',
          }}
      >
        {product && <Card sx={{ minWidth: 275, marginRight: '20px' }}>
          <CardContent>
            <h2>
              {product.productId}
            </h2>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {product.productName}
            </Typography>
            <Typography variant="body2">
              {product.notes || '- No notes'}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Edit</Button>
          </CardActions>
        </Card>}
        {averages && <Card sx={{ minWidth: 275, marginRight: '20px'  }}>
          <CardContent>
            <h3>
              Stock on Hand
            </h3>
            <h1 style={{textAlign: 'center'}}>{averages['Month1'].value}</h1>
          </CardContent>
        </Card>}
        {averages && <Card sx={{ minWidth: 275, marginRight: '20px' }}>
          <CardContent>
            <h3>
              Stock to order
            </h3>
            <h1 style={{textAlign: 'center'}}>{stockToOrder()}</h1>
          </CardContent>
        </Card>}
        {averages && <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <h3 sx={{ color: 'blue' }}>
              Stock on order
            </h3>
            <h1 style={{textAlign: 'center'}}>{averages['onOrder']}</h1>
          </CardContent>
        </Card>}
      </Box>
      {averages && <Container><h4>Stock Count</h4>
        <Chart
          data={columns.map((c) => {return {argument: averages[c].date, value: averages[c].value}})}
        >
          <ArgumentAxis />
          <ValueAxis />
          <LineSeries valueField="value" argumentField="argument" />

        </Chart>
      </Container>}
    </Container>

  );
}
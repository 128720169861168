import React, { useState, useEffect } from "react";
import { Alert, Box, Button, Autocomplete, Stack, Grid, Container, IconButton, FormControlLabel, Switch, TextField, Chip, ToggleButton, ToggleButtonGroup, ListItem, ListItemAvatar, Avatar, ListItemText, Skeleton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from 'moment';

export default function Notes() {
  const {allProducts, setAllProducts, allSuppliers, setAllSuppliers, allStock, setAllStock, selectedStore, imported, setImported } = useAppContext();
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productEmails, setProductEmails] = useState(JSON.parse(localStorage.getItem('productEmails') || "\[\]"))
  const [emailIsSending, setEmailIsSending] = useState(false);
  const [emailStatus, setEmailStatus] = useState(null)
  const [email, setEmail] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [hideNoExpectedShortage, setHideNoExpectedShortage] = useState(true);
  const [sortToggle, setSortToggle] = React.useState('qty');
  const [hover,sethover]=useState(false);
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'expected',
      sort: 'desc',
    },
  ]);
  let highlightedEmail = ""

  useEffect(() => {
    setProducts([])
  }, [selectedStore, imported])

  useEffect(() => {
    async function loadItem(all, setAll, path) {
      if (all.length < 1) {
        let result = await API.get("tbc", path)
        setAll(result.all || [])
        return (result.all || [])
      }
      return all
    }

    async function onLoad() {
      setProductsLoading(true)
      try {
        setIsLoading(true)
        let result = await Promise.all([loadItem(allProducts, setAllProducts, `/items/${selectedStore}Products`), loadItem(allSuppliers, setAllSuppliers, `/items/${selectedStore}Suppliers`),loadItem(allStock, setAllStock, `/items/${selectedStore}Stock`)]);
        let temp = result[0].map((p) => {
          let expected = undefined;
          let expectedMonth = undefined;
          let stock = result[2].find((s) => s.internalId === p.productId)
          if (stock) {
            expected = Math.ceil((stock['Month1'].value + stock['Month2'].value + stock['Month3'].value) / 3) - stock.onHand
            expectedMonth = expected && expected > 0 ? Math.ceil((expected / moment().daysInMonth()) * moment().endOf('month').diff(moment(), 'days')) : expected
          }
          return {
            ...p,
            ...stock,
            expected,
            expectedMonth
          }
        })


        setProducts(result[1].map((s) => {
          let supplierProducts = temp.filter((p) => s.supplierId === p.supplierId)
          return {
            ...s,
            supplierProducts,
            expected: supplierProducts.reduce(( pV, cV ) => cV.expected > 0 ? pV + cV.expected : pV, 0) 
          }

        }))
        setIsLoading(false)
        setImported(false)
        setProductsLoading(false)
      } catch (e) {
        onError(e);
        setProductsLoading(false)
      }
    }

    products.length === 0 && !productsLoading && onLoad();
  }, [products]);

  function updateRecentEmails(thisEmail, supplierId, deleteEmail = '') {
    console.error(thisEmail)
    let supplier = suppliers.find((s) => s.supplierId === supplierId)
    const existingRecentEmails = supplier.recentEmails || []
    let newRecentEmails = []
    if (deleteEmail.length > 0) {
      newRecentEmails = existingRecentEmails.filter((e) => e.email !== deleteEmail)
    } else {
      newRecentEmails = [
        {
          email: thisEmail,
          date: Date.now()
        },
        ...existingRecentEmails.filter((e) => e.email !== thisEmail)
      ]
    }
    API.put("tbc", "/suppliers", {
      body: {
        supplierId,
        warehouse: selectedStore,
        UpdateExpression: "SET recentEmails = :recentEmails",
        ExpressionAttributeValues: {
          ":recentEmails": newRecentEmails
        }
      }
    })
    setSuppliers([
      ...suppliers.filter((s) => s.supplierId !== supplierId),
      {
        ...supplier,
        recentEmails: newRecentEmails
      }
    ])
  }

  function rowClick(event) {
    history.push(`/products/${event.id}`);
  }

  function handleShareButtonClick(newEmail, id, products) {
    sendEmail(newEmail, id, products.filter((p) => p.expectedMonth > 0).sort((a, b) => b.expectedMonth - a.expectedMonth).map((p) => {
      return {
        'Product Id': p.productId,
        'Shortage for Month': p.expectedMonth,
        'On Hand': p.onHand
      }
    }))
  }

  function handleShareClick(id, products) {
    if (validateEmail(email)) {
      sendEmail(
        email,
        id, 
        products.filter((p) => p.expectedMonth > 0).sort((a, b) => b.expectedMonth - a.expectedMonth).map((p) => {
          return {
            'Product Id': p.productId,
            'Shortage for Month': p.expectedMonth,
            'On Hand': p.onHand
          }
        })
      )
    } else {
      setValidEmail(false)
    }
  }

  function validateEmail(email) {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true
    } else {
      return false
    }
  }

  function handleInputChange(event) {

    if (event && event.target) {
      if (Number.isInteger(event.target.value)) {
        setEmailValue(productEmails[event.target.dataset.optionIndex])
      } else {
        setEmailValue(event.target.value);
      }
    }
  }

  function handleFieldChange(event) {
    setEmailStatus(null);
    if (event && event.target && (event.target.value || Number.isInteger(event.target.value))) {
      if (Number.isInteger(event.target.value)) {
        setEmail(productEmails[event.target.dataset.optionIndex])
      } else {
        setEmail(event.target.value)
      }
    } else {
      setEmail("")
      setValidEmail(true)
    }
  }

  function handleExpandClick(id) {
    if (!suppliers.find((s) => s.supplierId === id)) {
      setSuppliers([...suppliers, {supplierId: id, loading: true}])
      API.get("tbc", `/suppliers/${selectedStore}/${id}`).then((result) => {
        setSuppliers([...suppliers, result])
      })
    }
    setExpanded([...expanded, id])
  }

  function handleCollapseClick(id) {
    setExpanded(expanded.filter((e) => e !== id))
  }

  function toggleShowEmail() {
    setShowEmail(!showEmail)
  }

  const handleSortToggleChange = (event, newAlignment) => {
    setSortToggle(newAlignment);
  };

  const handleHighlightChange = (event) => {
    if (event && event.target && event.target) {
      highlightedEmail = event.target.textContent
    } else {
      highlightedEmail = ""
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Delete' && highlightedEmail.length > 0) {
      let newEmails = productEmails.filter((p) => p !== highlightedEmail)
      localStorage.setItem('productEmails', (JSON.stringify(newEmails)));
      setProductEmails(newEmails)
    }
    if (event && event.target) {
      setEmailValue(event.target.value);
    }
  }

  async function sendEmail(thisEmail, id, products) {
    setEmailIsSending(true)

    let params = {
      subject: `Stock Re-order - ${allSuppliers.find((s) => s.supplierId === id).supplierName}`,
      text: 'Dear Rockstar,  please reorder the attached asap\n\nRemember to always stay Awesome.\n - TBC',
      to: [thisEmail],
      supplier: allSuppliers.find((s) => s.supplierId === id).supplierName,
      products,
      widths: [
        {wch:10},
        {wch:12},
        {wch:5}
      ]
    }
    let result = await API.post("tbc", "/email", {
        body: params
    })
    if (!productEmails.includes(thisEmail)) {
      localStorage.setItem('productEmails', (JSON.stringify([...productEmails, thisEmail])));
      setProductEmails([...productEmails, thisEmail])
    }
    setEmailIsSending(false)
    if (result && result.messageId) {
      updateRecentEmails(thisEmail, id)
      setEmailStatus({id, message: <Alert severity="success">Message sent to {thisEmail}!</Alert>})
    } else {
      setEmailStatus({id, message: <Alert severity="error">Something went wrong</Alert>})
    }
  }

  const columns = [
    { field: 'productId', headerName: 'Part Id', width: 100 },
    { field: 'productName', headerName: 'Part Name', width: 350 },
    { field: 'onHand', headerName: 'Available'},
    { field: 'expected', headerName: 'Expected Shortage 30 days', width: 250},
    { field: 'expectedMonth', headerName: 'Expected Shortage for month', width: 220}
  ];

  function collapsed(s) {
    return <Container><h3><IconButton color="primary" size="small" component="span" onClick={() => handleExpandClick(s.supplierId)}>
              <ExpandLessIcon />
            </IconButton>
          {s.supplierName} <Chip label={s.expected} color="primary" size="small"/>
        </h3></Container>
  }

  function generateEmailSection(s) {
    let supplier = suppliers.find((sp) => sp.supplierId === s.supplierId)
    console.error(supplier)
    return <Box id={`input-${s.supplierId}`} hidden={!showEmail}
      sx={{
        mb: 2,
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}><div>
        <Grid container sx={{ ml: 1}}>
          <Grid item xs={12} md={5}>
            { supplier.loading ? <Skeleton variant="circular" width={40} height={40} /> : supplier.recentEmails && supplier.recentEmails.map((e) => 
              <ListItem
              disablePadding
              secondaryAction={
                <>
                <IconButton aria-label="send" color="success" onClick={() => handleShareButtonClick(e.email, s.supplierId, s.supplierProducts)}>
                  <SendIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" color="error" onClick={() => updateRecentEmails(e.email, s.supplierId, e.email)}>
                  <DeleteIcon />
                </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={e.email}
                secondary={`Sent ${moment(e.date).fromNow()}`}
              />
            </ListItem>
            )
            }
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              fullWidth
              options={productEmails.map((e) => { return {label: e, id: e}}) || []}
              onChange={handleFieldChange}
              onInputChange={handleInputChange}
              onHighlightChange={handleHighlightChange}
              onKeyDown={handleKeyPress}
              renderInput={(params) => <TextField {...params} fullWidth id={`email-${s.supplierId}`} label="Email Address" error={!validEmail} size="small" helperText="Email to receive table data."/>}
            />
            {emailIsSending ? <LoadingButton sx={{ ml: 1, mb: 2}} endIcon={<SendIcon />} loading={emailIsSending} loadingPosition="end" variant="contained" >Send</LoadingButton> : <Button sx={{ ml: 1, mb: 2}} variant="contained" onClick={() => handleShareClick(s.supplierId, s.supplierProducts)} endIcon={<SendIcon />} disabled={email.length < 1 || !validEmail || emailValue !== email}>Send</Button>}
            </Grid></Grid>
        {emailStatus && emailStatus.id === s.supplierId ? emailStatus.message : null}
      </div>
    </Box>
  }

  function empty() {
    return <Container>
        No products identified for this store. Please use the import page to upload reports.
        <IconButton style={{ paddingTop: '5px' }} aria-label="add" onClick={() => history.push("/products/import")}  >
            <AddCircleIcon color={'primary'}/>
          </IconButton>
      </Container>
  }

  function table(s) {
    return <Container>
        <h3><IconButton color="primary" size="small" component="span" onClick={() => handleCollapseClick(s.supplierId)}>
            <ExpandMoreIcon />
          </IconButton>
          {s.supplierName}<IconButton color="primary" component="span" onClick={() => toggleShowEmail()}>
            <EmailIcon style={{ height: '20px' }}/>
          </IconButton></h3>
          {generateEmailSection(s)}
        <DataGrid style={{ height: '300px', padding: '5px' }} id={s.supplierId}
          getRowId={(row) => row.productId}
          disableSelectionOnClick
          loading={isLoading}
          onRowClick={rowClick}
          rows={s.supplierProducts} 
          pageSize={5}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          columns={columns} />
      </Container>
  }
  return (
    <Container>
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Container>
          <h1>Products</h1>
          <h3>All products split by Supplier</h3>          
          <FormControlLabel control={<Switch checked={hideNoExpectedShortage} onClick={() => {setHideNoExpectedShortage(!hideNoExpectedShortage)}}/>} label="Hide suppliers without expected shortage" /><br />
          <ToggleButtonGroup
            color="primary"
            value={sortToggle}
            size="small"
            exclusive
            onChange={handleSortToggleChange}
          >
            <ToggleButton value="a-z">A-Z</ToggleButton>
            <ToggleButton value="qty">Qty</ToggleButton>
          </ToggleButtonGroup>
        </Container>
      </Grid>
      <Grid item xs={4} justifyContent="flex-end">
        <Stack direction="row" justifyContent="end" style={{padding: '5px'}}>
          {hover && <h4 style={{margin: '14px 5px'}}>Import</h4>}
          <IconButton aria-label="add" onClick={() => history.push("/products/import")}  >
            <AddCircleIcon color={'primary'} fontSize="large" onMouseOver={()=>sethover(true)} 
     onMouseOut={()=>sethover(false)}/>
          </IconButton>
        </Stack>
          
      </Grid>
      <Container>{productsLoading ? <Container sx={{p: 5}} ><CircularProgress /></Container> : products.length < 1 ? empty() : products.sort((a, b) => sortToggle === 'qty' ? b.expected - a.expected : a.supplierName.localeCompare(b.supplierName)).map((s) => {
      if (hideNoExpectedShortage) {
        if (s.expected > 0) {
          return expanded.includes(s.supplierId) ? table(s) : collapsed(s)
        } else {
          return null
        }
      }
      return expanded.includes(s.supplierId) ? table(s) : collapsed(s)
    })}</Container>
      
    </Grid>
    </Container>
  );
}
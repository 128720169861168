import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useAppContext } from "../lib/contextLib";

const Notifications = () => {
    const { notifications } = useAppContext();
    return (
        <Stack>
            {notifications.map((n) => <Alert severity={n.severity}>{n.content}</Alert>)}
        </Stack>
    )
}
export default Notifications;
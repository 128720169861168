import React, { useState, useEffect } from "react";
import ResponsiveAppBar from './Components/Toolbar'
import Notifications from './Components/Notifications'
import Routes from "./Routes";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./lib/errorLib";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#00A390',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authedUser, setAuthedUser ] = useState({});
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [selectedStore, setSelectedStore] = useState(localStorage.getItem('selectedStore'));
  const [notifications, setNotifications] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allStock, setAllStock] = useState([]);
  const [imported, setImported ] = useState(false);
  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    setAllProducts([])
    setAllSuppliers([])
    setAllStock([])
  }, [selectedStore])
  
  async function onLoad() {
    try {
      let user = await Auth.currentSession();
      setAuthedUser(user)
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <ThemeProvider theme={theme}>
        <div className="App">
          <AppContext.Provider value={{
            isAuthenticated,
            allStock,
            allProducts,
            allSuppliers,
            authedUser,
            notifications,
            selectedStore,
            imported,
            userHasAuthenticated,
            setAllStock,
            setAllProducts,
            setAllSuppliers,
            setNotifications,
            setSelectedStore,
            setAuthedUser,
            setImported}}>
            {isAuthenticated && <ResponsiveAppBar />}
            <Notifications></Notifications>
            <Routes />
          </AppContext.Provider>
        </div>
      </ThemeProvider>
    )
  );
}

export default App;
